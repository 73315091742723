import { Button, Card, Rating, Stack, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

const ReviewRating = ({ data }) => {
    const [value, setValue] = useState(0)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (value > 3) {
            window.open(`${data.link}`, "_self")
        }
    }, [value])

    return (
        <Stack sx={{ backgroundColor: '#F3B419', minWidth: "100vw", height: "100vh" }} justifyContent='center' alignItems='center' spacing={2} >
            <Card sx={{ p: 2, width: 300 }}>
                {!submitted ? <Stack spacing={2} justifyContent='center' alignItems='center'>
                    <Typography sx={{ display: "flex", justifyContent: 'center', fontSize: 30, }}>
                        Quick Review
                    </Typography>
                    <Typography sx={{ display: "flex", justifyContent: 'center', fontSize: 20, }}>
                        Tell Us About Your Experience With Us
                    </Typography>
                    <Stack justifyContent='center' alignItems='center'>
                        <Typography sx={{ display: "flex", justifyContent: 'center', fontSize: 30, fontWeight: 'bold' }}>
                            Rate Us:
                        </Typography>
                        <Rating
                            size='large'
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }} />
                    </Stack>
                    <TextField size='small' label='Name' placeholder='Name' fullWidth />
                    <TextField size='small' multiline minRows={3} label='Comment' placeholder='Comment' fullWidth />
                    <Button variant='contained' color='inherit' onClick={() => {
                        if(value > 0){
                            setSubmitted(true)
                        }else{
                            enqueueSnackbar("Fill The Rating",{variant: 'error'})
                        }
                        }}>
                        Submit
                    </Button>
                </Stack> :
                    <Stack spacing={2} justifyContent='center' alignItems='center'>
                        <Typography sx={{ display: "flex", justifyContent: 'center', fontSize: 18, fontWeight: 'bold'}}>
                            Thanks for the detailed review.
                        </Typography>
                        <Typography sx={{ display: "flex", justifyContent: 'center', fontSize: 18, fontWeight: 'bold'}}>
                            Looking forward to applying your suggestions!
                        </Typography>
                    </Stack>
                }

            </Card>
        </Stack>
    )
}

export default ReviewRating
